<script setup lang="ts">
import { computed } from 'vue'
import { resolveRouteHref } from '../utils'
import type { AppRoute } from '../router'
import AppIcon from './AppIcon.vue'

const props = defineProps<{
  appRoute?: AppRoute
  currentAppRoute?: AppRoute
  href?: string
  icon: string
}>()

const href = computed(() => {
  if (props.href)
    return props.href

  if (props.appRoute)
    return resolveRouteHref({ name: props.appRoute })

  return '#'
})

const active = computed(() => {
  if (props.appRoute && props.currentAppRoute) {
    const splitted = props.currentAppRoute.toString().split('.')
    if (splitted[0])
      return props.appRoute.includes(splitted[0])

    return props.appRoute === props.currentAppRoute
  }

  return false
})
</script>

<template>
  <div class="relative px-2.5">
    <a
      :href="href"
      class="group flex select-none items-center overflow-hidden rounded-lg px-4 py-2.5 hover:bg-gray-200 active:bg-opacity-50 hover:bg-opacity-30 hover:no-underline"
      :class="{
        'text-gray-600 border-transparent': !active,
        'bg-gray-200 bg-opacity-30 text-primary border-primary font-medium': active,
      }"
      :target="props.href ? '_blank' : undefined"
    >
      <div v-if="active" class="absolute inset-y-0 w-10px flex rounded-r-lg bg-primary -left-6px" />
      <AppIcon
        :icon="props.icon"
        size="24px"
        class="mr-2.5"
        :class="{ 'opacity-70 group-hover:opacity-100': !active }"
      />
      <span class="text-lg leading-none"><slot /></span>
    </a>
  </div>
</template>
