{
  "active": "Aktiv",
  "add": "Hinzufügen",
  "add-countries": "Länder hinzufügen",
  "add-delivery-area": "Neues Liefergebiet",
  "add-holiday": "Neuer Feiertag",
  "add-widget": "Neues Widget",
  "additional-delivery-delay": "Zusätzliche Lieferverzögerung",
  "additional-delivery-delay-info": "Wird zur Lieferverzögerung in den allgemeinen Einstellungen hinzugefügt.",
  "additional-shipping-delay": "Zusätzliche Versandverzögerung",
  "additional-shipping-delay-info": "Wird zur Versandverzögerung in den allgemeinen Einstellungen hinzugefügt.",
  "all-regions": "Alle Regionen",
  "back": "Zurück",
  "billing": "Abrechnung",
  "billing-faq-1": "Wie kann ich bezahlen?",
  "billing-faq-1-answer": "Alle Zahlungen werden über dein Shopify-Konto abgewickelt.",
  "billing-faq-2": "Wie kündige ich mein Abo?",
  "billing-faq-2-answer": "Du kannst dein Abo jederzeit hier im Abrechnungsbereich kündigen.",
  "billing-feature-1": "Unbegrenzte monatliche Besucher und Bestellungen",
  "billing-feature-2": "Zugriff auf alle Funktionen und Updates",
  "billing-feature-3": "Technische Unterstützung über Live-Chat und E-Mail",
  "billing-subtitle": "Wähle einen Tarif und verwalte dein Abo.",
  "billing-whitelisted": "Dein Konto wurde von einem Admin in die Whitelist gesetzt. Du kannst Delm kostenlos nutzen.",
  "can-deliver": "Lieferungen können zugestellt werden",
  "can-ship": "Lieferungen können versendet werden",
  "cancel": "Abbrechen",
  "cancel-subscription": "Abo kündigen",
  "cancel-subscription-info": "Delm wird nach der Kündigung deaktiviert.",
  "cancel-subscription-title": "Wirklich kündigen?",
  "change-order-deadline": "Bestellfrist für Versand am selben Tag ändern",
  "changes-discarded": "Änderungen verworfen",
  "close": "Schließen",
  "common-questions": "Häufig gestellte Fragen",
  "content": "Inhalt",
  "continue": "Weiter",
  "continue-to-widgets": "Weiter zu Widgets",
  "copied": "Kopiert",
  "copied-to-clipboard": "In die Zwischenablage kopiert",
  "copy": "Kopieren",
  "count-languages": "Keine Sprachen | 1 Sprache | {count} Sprachen",
  "count-more-countries": "+{count} weitere Länder",
  "count-placeholders": "{count} Platzhalter",
  "count-selected-regions": "{selected} von {total} Regionen",
  "countdown-count-placeholder": "Zeigt den verbleibenden Zählerstand der Zeiteinheit an",
  "countdown-info": "Einstellungen für die Anzeige des Echtzeit-Countdowns in der Bestellfrist.",
  "countdown-placeholder": "Zeigt einen Countdown in Echtzeit an",
  "countries": "Länder",
  "country-already-selected": "In einem anderen Liefergebiet ausgewählt.",
  "country-code-emoji-placeholder": "Zeigt den Ländercode des Besucherlandes als Emoji-Flagge an. Emoji-Flaggen werden auf allen wichtigen Plattformen außer Windows unterstützt.",
  "country-code-lower-placeholder": "ISO 3166 Ländercode des Besuchers in Kleinbuchstaben",
  "country-code-upper-placeholder": "ISO 3166 Ländercode des Besuchers in Großbuchstaben",
  "country-name-placeholder": "Zeigt den Namen des Besucherlandes an",
  "css-rules": "CSS-Regeln für alle Templates",
  "css-selector": "CSS-Selektor",
  "css-selector-is-required": "CSS-Selektor ist ein Pflichtfeld.",
  "current-plan": "Dein aktueller Tarif",
  "custom-holidays": "Eigene Feiertage",
  "custom-holidays-info": "Priorisiert, wenn sie sich mit gesetzlichen Feiertagen überschneiden.",
  "customize": "Anpassen",
  "customize-delivery-area": "Liefergebiet anpassen",
  "customize-delivery-areas": "Liefergebiete anpassen",
  "customize-delivery-areas-info": "Transportzeiten ändern oder Liefergebiete deaktivieren.",
  "customize-product": "Produkt anpassen",
  "date-D-placeholder": "Der Tag des Monats",
  "date-DD-placeholder": "Der Tag des Monats, 2-stellig",
  "date-Do-placeholder": "Der Tag des Monats mit Ordnungszahl",
  "date-M-placeholder": "Der Monat",
  "date-MM-placeholder": "Der Monat, 2-stellig",
  "date-MMM-placeholder": "Der abgekürzte Monatsname",
  "date-MMM-placeholder-example": "Jan-Dez",
  "date-MMMM-placeholder": "Der vollständige Monatsname",
  "date-MMMM-placeholder-example": "Januar-Dezember",
  "date-YY-placeholder": "Zweistelliges Jahr",
  "date-YYYY-placeholder": "Vierstelliges Jahr",
  "date-dd-placeholder": "Die ersten 2 Zeichen des Wochentagsnamens",
  "date-dd-placeholder-example": "So-Sa",
  "date-ddd-placeholder": "Die ersten 3 Zeichen des Wochentagsnamens",
  "date-ddd-placeholder-example": "Son-Sam",
  "date-dddd-placeholder": "Der vollständige Name des Wochentags",
  "date-dddd-placeholder-example": "Sonntag-Samstag",
  "date-formats": "Datumsformate",
  "date-formats-info": "Die Datumsformate hängen vom voraussichtlichen Lieferdatum oder Versanddatum ab. Wenn du beispielsweise eine Transportzeit von 2-3 Tagen konfiguriert hast, wird das Datumsformat als Bereich angezeigt.",
  "date-range": "Datumsbereich",
  "date-range-earliest": "Datumsbereich frühestes Datum",
  "date-range-latest": "Datumsbereich spätestes Datum",
  "day": "Tag",
  "day-singular": "Tag (Einzahl)",
  "days": "Tage",
  "days-plural": "Tage (Mehrzahl)",
  "days-range": "Tage als Bereich",
  "default": "Standard",
  "default-language": "Standardsprache",
  "default-select-placeholder": "Option auswählen",
  "delete": "Löschen",
  "delete-delivery-area": "Liefergebiet löschen",
  "delete-delivery-area-info": "Möchtest du dieses Liefergebiet wirklich löschen?",
  "delete-widget": "Widget löschen",
  "delete-widget-confirm": "Möchtest du dieses Widget wirklich löschen?",
  "delete-widget-info": "Gelöschte Widgets können nicht wiederhergestellt werden.",
  "delivery-area": "Liefergebiet",
  "delivery-area-countries-info": "Gebe die Länder an, die zu diesem Liefergebiet gehören.",
  "delivery-area-settings-info": "Gebe die Transportzeit und die Liefertage für dieses Liefergebiet an.",
  "delivery-areas": "Liefergebiete",
  "delivery-areas-empty-title": "Erstelle dein erstes Liefergebiet",
  "delivery-areas-subtitle": "Konfiguriere Lieferzeiten abhängig vom Kundenstandort.",
  "delivery-date": "Lieferdatum",
  "delivery-date-info": "Das Datum, an dem eine Lieferung dem Kunden zugestellt wird.",
  "delivery-date-placeholder": "Zeigt das voraussichtliche Lieferdatum an",
  "delivery-days": "Liefertage",
  "delivery-days-info": "An welchen Tagen können Lieferungen zugestellt werden?",
  "delivery-delay": "Lieferverzögerung",
  "delivery-delay-info": "Lieferdatum für alle Produkte verzögern.",
  "delivery-messages": "Liefernachrichten",
  "delivery-messages-banner-info": "Liefernachrichten deaktivieren und stattdessen Widgets verwenden.",
  "delivery-messages-deprecated": "Diese Funktion ist veraltet und wird bald entfernt. Wir empfehlen allen Händlern diese Funktion zu deaktivieren und auf Widgets umzusteigen.",
  "delivery-messages-disabled": "Liefernachrichten deaktiviert",
  "delivery-messages-enabled": "Liefernachrichten aktiviert",
  "delivery-messages-languages-info": "Liefernachrichten werden automatisch in der Shop-Sprache angezeigt.",
  "delivery-messages-next-day-template": "Template für Lieferung am nächsten Tag",
  "delivery-messages-next-day-template-info": "Wird verwendet, wenn das Lieferdatum morgen ist.",
  "delivery-messages-range-template": "Template für Lieferdatum als Datumsbereich",
  "delivery-messages-range-template-info": "Wird verwendet, wenn die Transportzeit als Bereich konfiguriert ist, zum Beispiel 2-3 Tage.",
  "delivery-messages-single-template": "Template für einzelnes Lieferdatum",
  "delivery-messages-single-template-info": "Wird verwendet, wenn das Lieferdatum nicht morgen ist.",
  "delivery-messages-subtitle": "Konfiguriere wie voraussichtliche Liefertermine angezeigt werden.",
  "delivery-time": "Lieferzeit",
  "delivery-time-count-max-placeholder": "Die maximale Lieferzeit in Tagen",
  "delivery-time-count-min-placeholder": "Die minimale Lieferzeit in Tagen",
  "delivery-time-count-placeholder": "Die Lieferzeit in Tagen",
  "delivery-time-info": "Einstellungen für die Anzeige der Lieferzeit.",
  "delivery-time-placeholder": "Zeigt die Lieferzeit in Tagen an",
  "delivery-to": "Lieferung nach",
  "delm-is-disabled": "Delm ist deaktiviert.",
  "delm-is-enabled": "Delm ist aktiviert.",
  "delm-is-unsubscribed": "Delm ist deaktiviert, wähle zuerst einen Tarif.",
  "deprecated-feature": "Veraltete Funktion",
  "description": "Beschreibung",
  "disable-delivery-area": "Liefergebiet deaktivieren",
  "disable-delivery-messages": "Liefernachrichten deaktivieren",
  "disable-delm": "Delm deaktivieren",
  "disable-oversold-products": "Delm für überverkaufte Produkte deaktivieren",
  "disable-script-tag-api": "Shopify ScriptTag API deaktivieren",
  "disable-script-tag-api-info": "Aktiviere diese Option nur, wenn du unsere JavaScript-Datei manuell einbinden möchtest.",
  "disable-unavailable-products": "Delm für nicht verfügbare Produkte deaktivieren",
  "disabled": "Deaktiviert",
  "discard": "Verwerfen",
  "discard-dialog-info": "Alle Änderungen, die du seit dem letzten Speichern gemacht hast, werden gelöscht.",
  "discard-dialog-title": "Nicht gespeicherte Änderungen verwerfen",
  "display-delivery-message": "Widgets/Liefernachricht anzeigen",
  "docs": "Dokumentation",
  "docs-subtitle": "Erfahre mehr über Delm.",
  "dont-set-as-default": "Nicht als Standard festlegen",
  "draft": "Entwurf",
  "duplicate": "Duplizieren",
  "earliest-placeholder": "Das früheste Datum",
  "edit-delivery-area": "Liefergebiet bearbeiten",
  "edit-regions": "Regionen verwalten",
  "enable-all-products": "Delm für alle Produkte aktivieren",
  "enable-delivery-messages": "Liefernachrichten aktivieren",
  "enable-delm": "Delm aktivieren",
  "enabled": "Aktiviert",
  "enter-hours": "Stunden eingeben",
  "enter-shopify-domain": "Shopify-Domain eingeben",
  "error": "Es ist ein unerwarteter Fehler aufgetreten, bitte versuche es nochmal oder kontaktiere uns",
  "example": "Beispiel",
  "general-settings": "Einstellungen",
  "general-settings-products-info": "Allgemeine Einstellungen für Produkte.",
  "general-settings-subtitle": "Starte hier, um Delm zu konfigurieren.",
  "holiday": "Feiertag",
  "holidays": "Feiertage",
  "holidays-subtitle": "Erstelle Feiertage und prüfe bevorstehende gesetzliche Feiertage.",
  "home-subtitle": "Mehr Umsatz durch klare Lieferangaben: Liefertermine, Lieferzeiten und mehr anzeigen.",
  "home-title": "Willkommen bei Delm",
  "hour-singular": "Stunde (Einzahl)",
  "hours": "Stunden",
  "hours-plural": "Stunden (Mehrzahl)",
  "imprint": "Impressum",
  "info-created": "{name} erstellt",
  "info-deleted": "{name} gelöscht",
  "info-delm-disabled": "Delm deaktiviert",
  "info-delm-enabled": "Delm aktiviert",
  "info-demoted": "{name} ist nicht mehr als Standard festgelegt",
  "info-duplicated": "{name} dupliziert",
  "info-promoted": "{name} ist jetzt als Standard festgelegt",
  "info-saved": "{name} gespeichert",
  "internal-name": "Interner Name",
  "internal-name-info": "Für Kunden nicht sichtbar.",
  "language-names": {
    "ar": "Arabisch",
    "bg": "Bulgarisch",
    "cs": "Tschechisch",
    "da": "Dänisch",
    "de": "Deutsch",
    "el": "Griechisch",
    "en": "Englisch",
    "es": "Spanisch",
    "fi": "Finnisch",
    "fr": "Französisch",
    "hr": "Kroatisch",
    "hu": "Ungarisch",
    "it": "Italienisch",
    "ja": "Japanisch",
    "ko": "Koreanisch",
    "lt": "Litauisch",
    "lv": "Lettisch",
    "nb": "Norwegisch",
    "nl": "Niederländisch",
    "pl": "Polnisch",
    "pt": "Portugiesisch",
    "ro": "Rumänisch",
    "sk": "Slowakisch",
    "sl": "Slowenisch",
    "sv": "Schwedisch",
    "zh": "Chinesisch"
  },
  "languages": "Sprachen",
  "last-words-connector": "Letzte Wortverbindung",
  "latest-placeholder": "Das späteste Datum",
  "leave-page": "Seite verlassen",
  "leave-page-info": "Wenn du die Seite verlässt, gehen nicht gespeicherte Änderungen verloren.",
  "link-docs": "https://de.delm.io/dokumentation/",
  "link-docs-delivery-areas": "https://de.delm.io/dokumentation/liefergebiete.html",
  "link-docs-delivery-messages": "https://de.delm.io/dokumentation/liefernachrichten.html",
  "link-docs-disable-script-tag-api": "https://de.delm.io/dokumentation/liefernachrichten.html#shopify-scripttag-api-deaktivieren",
  "link-docs-general-settings": "https://de.delm.io/dokumentation/allgemeine-einstellungen.html",
  "link-docs-holidays": "https://de.delm.io/dokumentation/feiertage.html",
  "link-docs-products": "https://de.delm.io/dokumentation/produkte.html",
  "link-docs-support": "https://de.delm.io/kundenservice/",
  "link-docs-theme-integration": "https://de.delm.io/dokumentation/widgets.html#theme-integration",
  "link-docs-widgets": "https://de.delm.io/dokumentation/widgets.html",
  "link-imprint": "https://de.delm.io/impressum/",
  "link-privacy-policy": "https://de.delm.io/datenschutz/",
  "link-terms-of-service": "https://de.delm.io/agb/",
  "login": "Anmelden",
  "login-subtitle": "Weiter zu Delm",
  "logout": "Abmelden",
  "logout-dialog-info": "{name} wird abgemeldet.",
  "logout-dialog-title": "Wirklich abmelden?",
  "main-content": "Hauptinhalt",
  "manage-countries": "Länder verwalten",
  "manage-languages": "Sprachen verwalten",
  "merchant-working-days": "Versandtage und Bestellfristen",
  "merchant-working-days-info": "An welchen Tagen können Bestellungen versendet werden, und welche Bestellfrist gilt für den Versand am selben Tag?",
  "minute-singular": "Minute (Einzahl)",
  "minutes-plural": "Minuten (Mehrzahl)",
  "month-names": [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember"
  ],
  "more-settings": "Weitere Einstellungen",
  "must-be-greater-than-min-transit-time": "Muss größer als die minimale Transportzeit sein.",
  "must-be-greater-than-zero": "Die Mindestlaufzeit muss größer als 0 sein.",
  "must-be-zero-or-greater": "Muss 0 oder größer sein.",
  "myshopify-example": "beispiel.myshopify.com",
  "name": "Name",
  "name-is-required": "Name ist ein Pflichtfeld.",
  "national-holiday": "Nationaler Feiertag",
  "new-delivery-area": "Neues Liefergebiet",
  "new-widget": "Neues Widget",
  "next": "Weiter",
  "no": "Nein",
  "no-countries-added": "Keine Länder hinzugefügt",
  "no-countries-found": "Keine Länder gefunden",
  "no-delivery-areas": "Keine Liefergebiete",
  "no-languages-added": "Noch keine Sprachen hinzugefügt.",
  "no-products": "Keine Produkte",
  "no-products-found": "Keine Produkte gefunden.",
  "no-products-info": "Nach der Installation kann es ein paar Minuten dauern, bis Produkte synchronisiert wurden.",
  "no-upcoming-holidays": "Keine bevorstehenden Feiertage",
  "of": "von",
  "one-day-must-be-active": "Mindestens ein Tag muss aktiv sein.",
  "open-guide": "Anleitung öffnen",
  "order-deadline": "Bestellfrist",
  "order-deadline-content-info": "Du kannst HTML-Code verwenden, um das Erscheinungsbild und Layout des Inhalts anzupassen.",
  "order-deadline-info": "Einstellungen für die Anzeige der Bestellfrist zur Erhöhung der Dringlichkeit.",
  "order-deadline-placeholder": "Zeigt den Text der Bestellfrist an, der einen Echtzeit-Countdown enthält",
  "page-not-found": "Seite nicht gefunden",
  "page-not-found-subtitle": "Scheinbar wurde eine falsche Adresse eingegeben.",
  "partner-friendly-info": "Delm ist partnerfreundlich. Du kannst Delm ohne Abo nutzen, während sich der Shop im Entwicklungsmodus befindet.",
  "password": "Passwort",
  "placeholder": "Platzhalter",
  "placeholder-name": "{name} Platzhalter",
  "previous": "Zurück",
  "price-per-month": "Für {price}/Monat",
  "privacy-policy": "Datenschutz",
  "product": "Produkt",
  "product-settings-info": "Konfiguriere individuelle Produkteinstellungen.",
  "products": "Produkte",
  "products-subtitle": "Konfiguriere Lieferzeiten für einzelne Produkte.",
  "public-holidays": "Gesetzliche Feiertage",
  "public-holidays-info": "Wir stellen sicher, dass Liefertermine nicht auf Feiertage fallen, indem wir Feiertage des Kundenstandorts überprüfen.",
  "regions": "Regionen",
  "remaining-hours": "Verbleibende Stunden",
  "remove": "Entfernen",
  "remove-settings": "Einstellungen entfernen",
  "require-customer-location-match": "Übereinstimmung des Kundenstandorts voraussetzen",
  "require-customer-location-match-info": "Voraussichtliche Liefertermine nur anzeigen, wenn der Kundenstandort mit einem aktiven Liefergebiet übereinstimmt. Transportzeit und Liefertage werden vom Liefergebiet übernommen.",
  "save": "Speichern",
  "search-countries": "Länder suchen",
  "search-products": "Produkte suchen",
  "seconds": "Sekunden",
  "select-date": "Datum wählen",
  "select-language": "Sprache auswählen",
  "select-plan": "Tarif wählen",
  "set-as-default": "Als Standard festlegen",
  "settings": "Einstellungen",
  "shipping-date": "Versanddatum",
  "shipping-date-info": "Das Datum, an dem eine Lieferung an den Versanddienstleister übergeben wird.",
  "shipping-date-placeholder": "Zeigt das Versanddatum an",
  "shipping-delay": "Versandverzögerung",
  "shipping-delay-info": "Versanddatum für alle Produkte verzögern.",
  "show-leading-zeros": "Führende Nullen anzeigen",
  "show-more": "Mehr anzeigen",
  "show-seconds": "Sekunden anzeigen",
  "show-seconds-info": "Sekunden anzeigen, wenn die verbleibende Zeit geringer ist, sonst ausblenden. Auf Null setzen, um die Sekunden nicht anzuzeigen.",
  "show-zero-values": "Nullwerte anzeigen",
  "single-date": "Einzelnes Datum",
  "start-trial": "Kostenlos für {days} Tage testen",
  "status": "Status",
  "subscribe": "Abonnieren",
  "subscription-cancelled": "Abo gekündigt",
  "subscription-required-info": "Zur Aktivierung von Delm ist ein aktives Abo erforderlich.",
  "support": "Hilfe",
  "support-subtitle": "Kontaktiere uns, wenn du Fragen hast.",
  "terms-of-service": "AGB",
  "theme-integration": "Theme-Integration",
  "theme-integration-info": "Verwende den Delm-Widget-App-Block oder integriere den App-Block-Code in Liquid-Templates.",
  "timezone": "Basiszeitzone für Datumsberechnungen",
  "to": "bis",
  "today-format": "Heute",
  "today-placeholder": "Zeigt das aktuelle Datum im Format \"Einzelnes Datum\" an",
  "tomorrow-format": "Morgen",
  "transit-days": "Transport-Tage",
  "transit-days-info": "An welchen Tagen können Lieferungen transportiert werden? Blockierte Tage werden nicht auf die Transportzeit angerechnet.",
  "transit-time": "Transportzeit",
  "transit-time-info": "Wie lange dauert es, bis eine Lieferung den Kunden erreicht, nachdem sie versandt wurde?",
  "unlimited-plan": "Unbegrenzt",
  "unsaved-changes": "Nicht gespeicherte Änderungen",
  "until": "bis",
  "update": "Speichern",
  "use-default-value": "Standardwert verwenden",
  "visibility": "Sichtbarkeit",
  "visibility-info": "Anzeigen, wenn die Zeit kürzer ist, sonst ausblenden. Auf Null setzen, um immer anzuzeigen.",
  "weekday-names": [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag"
  ],
  "weekday-substring-end": "2",
  "widget": "Widget",
  "widget-add-languages": "Sprachen hinzufügen",
  "widget-add-languages-info": "Der Inhalt des Widgets wird pro Sprache bearbeitet.",
  "widget-content": "Widget-Inhalt",
  "widget-content-info": "Gib den Inhalt ein, den du in deinem Widget anzeigen möchtest. Du kannst HTML-Code verwenden, um das Erscheinungsbild und Layout des Inhalts anzupassen.",
  "widget-default-info": "Das Standard-Widget wird verwendet, wenn kein anderes Widget angegeben ist.",
  "widget-id": "Widget ID",
  "widget-id-empty-info": "Widget speichern, um die ID zu sehen.",
  "widget-id-info": "Einzigartige permanente ID zum Laden eines bestimmten Widgets.",
  "widget-name-example": "Zum Beispiel \"Produktseite v1\"",
  "widget-position": "Position auf Produktseiten",
  "widget-positions": {
    "after": "Nach dem ersten Element, das dem CSS-Selektor entspricht",
    "append": "An das erste Element anhängen, das dem CSS-Selektor entspricht",
    "before": "Vor dem ersten Element, das dem CSS-Selektor entspricht",
    "prepend": "Dem ersten Element, das dem CSS-Selektor entspricht, vorangestellt"
  },
  "widget-status-info": "Widgets im Entwurf sind nur im Shopify-Theme-Editor sichtbar.",
  "widget-template": "Widget template",
  "widgets": "Widgets",
  "widgets-empty-title": "Erstelle dein erstes Widget",
  "widgets-subtitle": "Erstelle Widgets zur Anzeige von Lieferterminen, Lieferzeiten und mehr. Binde Widgets über App-Block oder Liquid-Code ein.",
  "words-connector": "Wortverbindung",
  "yes": "Ja"
}
