import type { App } from 'vue'
import { createApp } from 'vue'
import AppSnackbar from '../components/AppSnackbar.vue'

interface AppSnackbarProps {
  message: string
  timeout?: number
  error?: boolean
}

function createContainer() {
  document.querySelector('#app-snackbar')?.remove()

  const container = document.createElement('div')
  container.id = 'app-snackbar'

  document.body.appendChild(container)
  return container
}

export function useAppSnackbar() {
  let app: App
  function mount(props: AppSnackbarProps) {
    app?.unmount()

    app = createApp(AppSnackbar, props as any)
    app.mount(createContainer())
  }

  function showInfo(message: string) {
    mount({ message })
  }

  function showError(message: string) {
    mount({
      message,
      error: true,
    })
  }

  return { showInfo, showError }
}
