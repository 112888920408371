import { ref } from 'vue'
import { useBuildVersion } from '../composables/build'
import type { User } from '../types/api'

const user = ref<User>()
export function useUser() {
  function setUser(_user?: User) {
    user.value = _user
    if (!_user)
      return

    const crisp = (window as any).$crisp
    if (crisp) {
      crisp.push(['set', 'user:email', [_user.email]])
      crisp.push(['set', 'user:nickname', _user.shopDomain])
    }

    const smartlook = (window as any).smartlook
    if (smartlook) {
      smartlook('identify', _user.shopDomain, {
        email: _user.email,
        build: useBuildVersion(),
      })
    }
  }
  return { user, setUser }
}
