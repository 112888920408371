<script setup lang="ts">
import { mdiLoading } from '@mdi/js'
import AppIcon from './AppIcon.vue'

const props = defineProps<{
  size?: string
}>()
</script>

<template>
  <AppIcon :icon="mdiLoading" :size="props.size" class="animate-spin animate-duration-450" />
</template>
