<script setup lang="ts">
import AppIcon from './AppIcon.vue'

const props = defineProps<{
  icon: string
  href?: string
  external?: boolean
}>()

const emit = defineEmits<{
  (event: 'click', value: MouseEvent): void
}>()

function clickHandler(event: MouseEvent) {
  if (props.href && props.external) {
    window.open(props.href, '_blank')
    return
  }
  emit('click', event)
}
</script>

<template>
  <a
    class="group flex select-none items-center whitespace-nowrap rounded-lg py-2 pl-3 pr-8 text-gray-700 leading-none last:border-0 hover:bg-gray-100 hover:no-underline"
    :href="href || '#'"
    @click.prevent="clickHandler"
  >
    <AppIcon size="20px" class="mr-3.5 opacity-80 group-hover:opacity-100" :icon="icon" />
    <slot />
  </a>
</template>
