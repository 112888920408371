<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import type { AxiosError } from 'axios'
import AppLoadingSpinner from './components/AppLoadingSpinner.vue'
import { deepClone } from './utils'
import { AppRoute, preloadAsyncRoutes } from './router'
import * as api from './api'
import AppLayout from './components/AppLayout.vue'
import { useUser } from './composables/user'
import { useAppSnackbar } from './composables/appSnackbar'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { setUser } = useUser()
const { showError } = useAppSnackbar()

const logoutDialog = ref(false)
const logoutLoading = ref(false)
async function logout() {
  try {
    logoutLoading.value = true
    await api.logout()
    await router.push({ name: AppRoute.Login })
    setUser(undefined)
  }
  catch (err) {
    logoutLoading.value = false
    showError(t('error'))
    throw err
  }
  finally {
    logoutLoading.value = true
  }
}

async function removeShopifyQueryParams() {
  await router.replace({
    query: {
      ...route.query,
      code: undefined,
      hmac: undefined,
      host: undefined,
      session: undefined,
      shop: undefined,
      state: undefined,
      timestamp: undefined,
    },
  })
}

const ready = ref(false)
onMounted(async () => {
  try {
    await router.isReady()
    await api.csrfCookie()
    const withShopifyQueryParams = deepClone(route.query)
    removeShopifyQueryParams()

    if (route.query.shop) {
      const response = await api.login(withShopifyQueryParams)
      if (response.data.authUrl) {
        window.location.href = response.data.authUrl
        return
      }
      if (response.data.user) {
        setUser(response.data.user)
        return
      }
    }

    await api.checkAuth()
      .then((response) => {
        if (response.data.authUrl) {
          window.location.href = response.data.authUrl
          return
        }
        if (response.data.user)
          setUser(response.data.user)
      })
      .catch(async (err: AxiosError) => {
        if (err.response?.status === 401) {
          setUser(undefined)
          await router.replace({ name: AppRoute.Login, query: route.query })
        }
        else {
          throw err
        }
      })
  }
  catch (err) {
    setUser(undefined)
    await router.replace({ name: AppRoute.Login })
    showError(t('error'))
    throw err
  }
  finally {
    ready.value = true
  }
})

onMounted(() => preloadAsyncRoutes(router))

const currentAppRoute = computed(() => {
  return route.name as AppRoute
})
</script>

<template>
  <div v-if="!ready" class="h-screen w-full flex items-center justify-center">
    <AppLoadingSpinner size="28px" class="text-gray-500" />
  </div>
  <div v-else>
    <Transition appear appear-active-class="animated animate-fade-in animate-duration-200">
      <div>
        <AppLayout
          :layout="route.meta.layout"
          :current-route="currentAppRoute"
          :logout-dialog="logoutDialog"
          :logout-loading="logoutLoading"
          @update:logout-dialog="value => logoutDialog = value"
          @logout="logout()"
        >
          <RouterView />
        </AppLayout>
      </div>
    </Transition>
  </div>
</template>
