<script setup lang="ts">
import { onMounted, onUnmounted } from 'vue'

const props = defineProps<{
  modelValue?: boolean
  persistent?: boolean
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: boolean): void
}>()

function close() {
  if (!props.persistent)
    emit('update:modelValue', false)
}

function escapeKeyHandler(event: KeyboardEvent) {
  if (event.key === 'Escape')
    close()
}

onMounted(() => document.addEventListener('keydown', escapeKeyHandler))
onUnmounted(() => document.removeEventListener('keydown', escapeKeyHandler))
</script>

<template>
  <teleport to="body">
    <transition
      name="content"
      enter-active-class="animated animate-fade-in animate-duration-50"
      leave-active-class="animated animate-fade-out animate-duration-50"
    >
      <div
        v-if="modelValue"
        class="items-strech fixed inset-0 z-9998"
        @click.self="close()"
      >
        <div class="h-screen w-full flex justify-center px-4 pt-4" @click.self="close()">
          <div class="overflow-y-auto" @click.self="close()">
            <slot />
          </div>
        </div>
      </div>
    </transition>

    <transition
      name="backdrop"
      enter-active-class="animated animate-fade-in animate-duration-50"
      leave-active-class="animated animate-fade-out animate-duration-50"
    >
      <div
        v-if="modelValue"
        class="fixed inset-0 z-9997 h-screen w-screen bg-gray-900 bg-opacity-50 backdrop-blur-2px backdrop-filter"
        @click.self="close()"
      />
    </transition>
  </teleport>
</template>
