<script setup lang="ts">
import { computed } from 'vue'
import type { AppRoute } from '../router'
import AppDefaultLayout from './AppDefaultLayout.vue'

const props = defineProps<{
  layout?: 'default' | 'blank'
  currentRoute?: AppRoute
  logoutDialog?: boolean
  logoutLoading?: boolean
}>()

const emit = defineEmits<{
  (event: 'logout'): void
  (event: 'update:logoutDialog', value: boolean): void
}>()

const layout = computed(() => props.layout ?? 'default')
</script>

<template>
  <div v-if="layout === 'blank'">
    <slot />
  </div>
  <AppDefaultLayout
    v-else
    :current-route="props.currentRoute"
    :logout-dialog="props.logoutDialog"
    :logout-loading="props.logoutLoading"
    @update:logout-dialog="value => emit('update:logoutDialog', value)"
    @logout="emit('logout')"
  >
    <slot />
  </AppDefaultLayout>
</template>
