<script setup lang="ts">
import { useLayout } from '../composables/layout'

const props = defineProps<{
  flexAuto?: boolean
}>()

const { containerWidth, sidebarOffset } = useLayout()
</script>

<template>
  <div
    class="px-4 md:px-8"
    :class="{ 'flex flex-col flex-auto': props.flexAuto }"
    :style="{ marginLeft: sidebarOffset ? `${sidebarOffset}px` : undefined }"
  >
    <div
      class="mx-auto w-full"
      :class="{ 'flex flex-col flex-auto': props.flexAuto }"
      :style="{
        maxWidth: `${containerWidth}px`,
      }"
    >
      <slot />
    </div>
  </div>
</template>
