<script setup lang="ts">
import { computed } from 'vue'
import 'flag-icons/css/flag-icons.min.css'

const props = defineProps<{
  code: string
  size: string
}>()

const code = computed(() => {
  return props.code.toLowerCase()
})

const size = computed(() => {
  return `${props.size}!important`
})
</script>

<template>
  <div
    class="border-2px border-white rounded-full p-1px ring-1 ring-gray-300"
    :class="`fi fis fi-${code}`" :style="{
      height: size,
      minHeight: size,
      maxHeight: size,
      width: size,
      minWidth: size,
      maxWidth: size,
    }"
  />
</template>
