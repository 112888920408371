import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import type { App } from 'vue'
import type { Router } from 'vue-router'

export function initSentry(options: {
  app: App
  router: Router
  dsn: string
  environment?: string
  release?: string
  tracesSampleRate?: number
}) {
  Sentry.init({
    app: options.app,
    dsn: options.dsn,
    environment: options.environment,
    release: options.release,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(options.router),
        tracingOrigins: ['localhost', /^\//],
      }),
    ],
    tracesSampleRate: options.tracesSampleRate,
  })
}
