<script setup lang="ts">
import { mdiAlert } from '@mdi/js'
import { onMounted, ref } from 'vue'
import { useLayout } from '../composables/layout'
import AppIcon from './AppIcon.vue'

const props = withDefaults(defineProps<{
  message: string
  timeout?: number
  error?: boolean
}>(), {
  timeout: 3000,
})

const { sidebarOffset } = useLayout()

const visible = ref(props.error)
onMounted(() => {
  if (!props.error && props.timeout) {
    visible.value = true
    setTimeout(() => {
      visible.value = false
    }, props.timeout)
  }
})
</script>

<template>
  <transition
    enter-active-class="animated animate-fade-in-up animate-duration-100"
    leave-active-class="animated animate-fade-out-down animate-duration-100"
  >
    <div
      v-if="visible"
      class="pointer-events-none fixed bottom-0 left-0 right-0 z-9999 flex items-center justify-center"
      :style="{
        marginLeft: `${sidebarOffset}px`,
      }"
      @click="visible = false"
    >
      <div class="pointer-events-auto relative m-1.5 flex cursor-pointer items-center rounded-full bg-dark-500 px-6 py-3 text-gray-100">
        <AppIcon
          v-if="error"
          :icon="mdiAlert"
          size="22px"
          class="mr-2 text-red-500"
        />
        <div class="text-lg">
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>
