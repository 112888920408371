<script setup lang="ts">
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { mdiChevronDown } from '@mdi/js'
import { deepEquals } from '../utils'
import type { AppSelectOption } from '../types/components'
import AppIcon from './AppIcon.vue'

const props = defineProps<{
  modelValue?: any
  options: AppSelectOption[]
  placeholder?: string
  size?: 'sm'
  disabled?: boolean
}>()

const emit = defineEmits<{
  (event: 'update:modelValue', value: any): void
}>()

const { t } = useI18n()

const selectedOption = computed(() => {
  if (props.modelValue !== undefined && props.options.length) {
    return props.options.find((item) => {
      return deepEquals(item.value, props.modelValue)
    })
  }
  return null
})

function updateHandler(event: Event) {
  if (props.options) {
    const target = event.target as HTMLSelectElement
    const index = Number.parseInt(target.value)
    emit('update:modelValue', props.options[index].value)
  }
}

const focused = ref(false)
</script>

<template>
  <div
    class="relative flex select-none items-center dark:text-gray-400 dark:hover:text-gray-50"
    :class="{ 'opacity-40 pointer-events-none': props.disabled }"
  >
    <select
      class="w-full cursor-pointer appearance-none rounded bg-white pr-9 shadow outline-none ring-1 ring-gray-300 dark:bg-gray-900 dark:ring-gray-700 hover:ring-gray-400 !focus:ring-primary dark:hover:ring-gray-400"
      :class="{
        'h-36px pl-4': !props.size,
        'h-32px pl-2.5': props.size === 'sm',
      }"
      :disabled="props.disabled"
      @change="value => updateHandler(value)"
      @focus="focused = true"
      @blur="focused = false"
    >
      <option
        v-if="props.placeholder || !selectedOption"
        value
        disabled
        :selected="!selectedOption"
      >
        {{ props.placeholder ? props.placeholder : t('default-select-placeholder') }}
      </option>
      <option
        v-for="(option, index) in props.options"
        :key="`option-${index}`"
        :value="index"
        :selected="selectedOption === option"
      >
        {{ option.title }}
      </option>
    </select>
    <div class="pointer-events-none absolute right-0 pr-3 leading-none">
      <AppIcon :icon="mdiChevronDown" size="20px" />
    </div>
  </div>
</template>
