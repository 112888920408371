import { useWindowSize } from '@vueuse/core'
import { computed, ref, watch } from 'vue'

const windowSize = useWindowSize()
const isMobile = computed(() => {
  return windowSize.width.value < 768
})

const headerHeight = ref(52)
const containerWidth = ref(1020)

const sidebarWidth = ref(280)
const sidebarVisible = ref(true)
watch(
  sidebarVisible,
  () => {
    if (!isMobile.value)
      return

    if (sidebarVisible.value)
      document.body.style.overflow = 'hidden'
    else
      document.body.style.overflow = 'auto'
  },
  { immediate: true },
)
watch(
  isMobile,
  () => sidebarVisible.value = !isMobile.value,
  { immediate: true },
)

function hideSidebar() {
  sidebarVisible.value = false
}

function toggleSidebar() {
  sidebarVisible.value = !sidebarVisible.value
}

const sidebarOffset = computed(() => {
  if (isMobile.value)
    return 0

  if (sidebarVisible.value)
    return sidebarWidth.value

  return 0
})

const headerOffset = computed(() => {
  if (isMobile.value)
    return 0

  return headerHeight.value
})

export function useLayout() {
  return {
    containerWidth,
    headerHeight,
    headerOffset,
    hideSidebar,
    isMobile,
    sidebarOffset,
    sidebarVisible,
    sidebarWidth,
    toggleSidebar,
  }
}
